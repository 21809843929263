import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import axios from 'axios';
import vClickOutside from "click-outside-vue3"
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/saas/app.scss';
import '@vueform/slider/themes/default.css';

axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response && error.response.status === 401)
        router.push('/login');
    if(error.response && error.response.status === 404)
        router.push('/404');
    if(error.response && error.response.status === 500)
        router.push('/500');
    return Promise.reject(error);
});

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

createApp(App)
    .use(store)
    .use(router)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside).mount('#app')